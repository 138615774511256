.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (--mobile) {
    margin-bottom: var(--spacing-5);
  }
  @media (--tablet) {
    margin-bottom: var(--spacing-6);
  }
  @media (--tablet-l) {
    margin-bottom: var(--spacing-8);
  }
  @media (--desktop) {
    margin-bottom: var(--spacing-10);
  }
}

.title {
  margin-top: 0;
  margin-bottom: 0 !important;
}

.border {
  border-bottom: 1px solid var(--color-neutral-300);
  width: 100%;
  &:last-child {
    border-bottom: none;
  }
}

.gridContainer {
  display: grid;
  grid-template-columns: 1fr;

  @media (--tablet-l) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--desktop-m) {
    grid-template-columns: repeat(6, 1fr);
  }
}

.priorityItem {
  border-bottom: 1px solid var(--color-neutral-300);

  @media (--tablet-l) {
    margin-right: var(--spacing-2);
    border-bottom: none;
    & > h5 {
      font-size: 20px;
    }
  }

  @media (--desktop-m) {
    grid-column: 1/4;
    margin-right: var(--spacing-3);
    border-bottom: none;
  }
}

.otherItems {
  @media (--tablet-l) {
    margin-left: var(--spacing-6);
    & > div:first-child > p:first-child {
      margin-top: 0px;
    }
  }

  @media (--desktop-m) {
    grid-column: 4 / 7;
    margin-left: var(--spacing-7);
    & > div:first-child > p:first-child {
      margin-top: 4px;
    }
  }
}
